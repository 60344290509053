<script>
	import { t } from '@woltair/translations-package-fe'
	import { MEETING } from '$lib/routes'
	import { Button, Separator } from '$lib/ui-woltair/components'
</script>

<div class="contact-cont">
	<Separator align="left" />
	<h2 class="mb-6 !font-extrabold tracking-normal">
		{t('web.homepage.contacts.title')}
	</h2>
	<p class="mb-6 text-lg">{t('web.homepage.contacts.desc')}</p>
	<img
		loading="lazy"
		class="technician-avatars"
		src="/images/avatars/technicians.webp"
		alt={t('web.homepage.contacts.img.alt')}
		width="620"
		height="120"
	/>
	<Button href="/{MEETING}" cls="mb-3">{t('web.homepage.contacts.button')}</Button>
</div>

<style lang="scss">
	.contact-cont {
		width: 50%;
		text-align: left;
		margin-right: 100px;
		@media only screen and (max-width: 760px) {
			float: none;
			width: 100%;
			margin-right: 0;
		}

		.technician-avatars {
			width: 300px;
			margin-bottom: 30px;
			display: block;
		}
	}
</style>
