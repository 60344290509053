<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { sendGTM } from '$lib/analytics'
	import Header from '$lib/components/header-2.0/Header.svelte'
	import WebTabs from '$lib/components/web-tabs'
	import { INSTALLATION_CATEGORY_URL } from '$lib/consts'
	import { BlogBanner } from '$lib/modules/blog'
	import ContactsBanner from '$lib/modules/contact/ContactsBanner.svelte'
	import AdvantageBox from '$lib/modules/products/AdvantageBox.svelte'
	import {
		HEAT_PUMP,
		PHOTOVOLTAICS,
		LEAVE_CONTACT,
		EXPERT_ADVICE,
		WOLTADVISOR,
		SERVICE,
		FINANCING,
		HEAT_PUMP_3050,
		PHOTOVOLTAICS_3050
	} from '$lib/routes'
	import { Button, Separator } from '$lib/ui-woltair/components'
	import type { PageData } from './$types'
	import Topics from './(features-support)/[expert_advice=expert_advice]/@modules/Topics.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	//import { LoginLevel } from '$lib/types/core.js'

	export let data: PageData

	export const header = {
		heading: t('web.homepage.PUBLIC.header.heading'),
		subheading: t('web.homepage.PUBLIC.header.subheading', {
			linkHVAC: `/${HEAT_PUMP}`,
			linkPV: `/${PHOTOVOLTAICS}`
		}),
		references_subtext: t('web.homepage.PUBLIC.header.referencesSubtext'),
		subtext: t('web.homepage.PUBLIC.header.subtext', {
			linkHVAC3050: HEAT_PUMP_3050 ? `/${HEAT_PUMP_3050}` : `#`,
			linkPV3050: PHOTOVOLTAICS_3050 ? `/${PHOTOVOLTAICS_3050}` : `#`
		})
	}

	let woltadvisorLink = `/${WOLTADVISOR}`

	if (data.featureConfig.woltadvisor.hvac && !data.featureConfig.woltadvisor.pv) {
		woltadvisorLink = `/${HEAT_PUMP}/${WOLTADVISOR}`
	}

	const icons = {
		_grant_: '/images/icons/grant.svg',
		_warranty_: '/images/icons/warranty.svg',
		_bolt_: '/images/icons/bolt.svg',
		default: '/images/icons/guarantee.webp'
	}

	const iconsSetter = {
		block1: icons.hasOwnProperty(`${t('web.homepage.strip.block1.iconName')}`)
			? icons[`${t('web.homepage.strip.block1.iconName')}`]
			: icons.default,
		block2: icons.hasOwnProperty(`${t('web.homepage.strip.block2.iconName')}`)
			? icons[`${t('web.homepage.strip.block2.iconName')}`]
			: icons.default,
		block3: icons.hasOwnProperty(`${t('web.homepage.strip.block3.iconName')}`)
			? icons[`${t('web.homepage.strip.block3.iconName')}`]
			: icons.default
	}
</script>

<!-- TODO: Delete the PUBLIC_WOLTAIR_WEB_CODE condition once DE references will be ready -->
<Header
	{header}
	references={data.references?.references?.content}
	showReferences={data.featureConfig.references.hvac ||
		data.featureConfig.references.pv ||
		PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_DE' ||
		PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_IT'}
	showSubtext={PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_CZ' &&
		PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_PL' &&
		(data.featureConfig.thirtyFifty.hvac || data.featureConfig.thirtyFifty.pv)}
	headerAsterisk={PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_CZ'}
>
	<svelte:fragment slot="buttonArea">
		<div class="relative">
			{#if data.featureConfig.woltadvisor.pv || data.featureConfig.woltadvisor.hvac}
				<div class="flex pt-8 md:-mt-2-res md:pt-16">
					<a
						class="btn btn-filled-primary text-base-res"
						href={woltadvisorLink}
						data-testid="page-link-calculator">{t('web.homepage.PUBLIC.header.calculator.text')}</a
					>

					<div class="relative hidden min-h-full w-full md:block">
						<img
							src="/images/icons/arrow-handdrawn.svg"
							alt=""
							class="absolute bottom-8 right-3/4 max-h-8 w-1/5"
						/>
						<img
							src="/images/misc/head-offer-pv.webp"
							alt=""
							class="absolute -bottom-2 right-0 max-h-28 w-8/12 object-contain md:-top-12"
						/>
					</div>
				</div>
			{/if}
			{#if data.featureConfig.meeting.hvac || data.featureConfig.meeting.pv}
				<div>
					<a
						class="btn btn-outline-accent-red wrap relative z-10 mt-4 text-base-res"
						href="/{LEAVE_CONTACT}"
						data-testid="meeting"
						on:click={() => sendGTM('online_meet')}
						>{t('web.forms.leaveContact.button')}
					</a>
				</div>
			{/if}
		</div>
	</svelte:fragment>
</Header>

<section class="page-fullwidth bg-neutral-100">
	<div class="page-wrapper relative px-4 pb-12">
		<div
			class="header-advantages page-wrapper align-start relative box-content flex w-full flex-col justify-around gap-8 border-t-2 border-neutral-300 px-0 py-4 lg:flex-row"
		>
			<div class="flex items-center gap-6-res lg:max-w-[50%]">
				<img
					src={iconsSetter.block1}
					class="aspect-square h-full max-h-11"
					alt={t('web.homepage.strip.block1.iconAlt')}
					width="41.25"
					height="41.25"
				/>
				<p class="unstyled mt-0 text-lg">
					{t('web.homepage.strip.block1')}
				</p>
			</div>
			<div class="flex items-center gap-6-res lg:max-w-[50%]">
				<img
					src={iconsSetter.block2}
					class="aspect-square h-full max-h-11"
					alt={t('web.homepage.strip.block2.iconAlt')}
					width="41.25"
					height="41.25"
				/>
				<p class="unstyled mt-0 text-lg">
					{t('web.homepage.strip.block2')}
				</p>
			</div>
			<div class="flex items-center gap-6-res lg:max-w-[50%]">
				<img
					src={iconsSetter.block3}
					class="aspect-square h-full max-h-11"
					alt={t('web.homepage.strip.block3.iconAlt')}
					width="41.25"
					height="41.25"
				/>
				<p class="unstyled mt-0 text-lg">
					{t('web.homepage.strip.block3')}
				</p>
			</div>
		</div>

		{#if PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ' && new Date() < new Date('2023-10-15')}
			<div class="mt-5">
				<a href="/chytre-tepelne-cerpadlo-wltr-akce">
					<img
						class="md:hidden"
						src="/images/banners/wltr-hvac-banner-2023-mobile.webp"
						alt="Tepelné čerpadlo WLTR se zárukou 5 let"
						width="760"
						height="360"
					/>
					<img
						class="hidden md:block"
						src="/images/banners/wltr-hvac-banner-2023-desktop.webp"
						alt="Tepelné čerpadlo WLTR se zárukou 5 let"
						width="1360"
						height="184"
					/>
				</a>
			</div>
		{/if}

		{#if data.featureConfig.thirtyFifty.hvac && PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_PL'}
			<section class="page-wrapper">
				<div
					class="mb-8 mt-10 grid grid-cols-1 items-center gap-8 rounded-2xl bg-gradient-to-r from-primary-500 to-[#e0154a] px-6 py-10 text-white shadow-2xl lg:grid-cols-12"
				>
					<div class="flex justify-center text-center text-6.5xl font-extrabold lg:col-span-3">
						{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE'}
							<img src="/images/logos/30-50-logo-DE.webp" class="max-h-44" alt="30/50" />
						{:else}
							30/50
						{/if}
					</div>
					<div
						class="max-sm:center text-lg font-extrabold md:text-left md:text-2.5xl lg:col-span-6"
					>
						{t('web.homepage.PUBLIC.block3050.mainText')}
					</div>
					<div class="center text-center lg:col-span-3">
						<a
							href="/{HEAT_PUMP_3050}"
							class="whitespace-nowrap rounded-full bg-neutral-800 px-8 py-4 text-base text-white"
							>{t('web.homepage.PUBLIC.block3050.button.text')}</a
						>
					</div>
				</div>
			</section>
		{/if}
	</div>
</section>

<section class="header page-fullwidth z-0 bg-neutral-100 pb-16-res" />
<section class="boxes page-wrapper relative z-20 -mt-20-res grid gap-5 lg:grid-cols-2">
	<AdvantageBox
		textWidth="lg:basis-1/2"
		subtextWidth="max-w-[80%]"
		imageCls="scale-125 lg:scale-150 lg:translate-x-0 lg:translate-y-2"
		content={{
			header: 'web.homepage.box.first.heading',
			text: 'web.homepage.box.first.text',
			buttonText: 'web.homepage.box.first.buttonText',
			buttonUrl: `/${HEAT_PUMP}`,
			buttonTestId: 'page-link-hvac',
			headingAnchor: `/${HEAT_PUMP}`,
			imageAnchor: `/${HEAT_PUMP}`,
			imageUrl:
				{
					WOLTAIR_CZ: '/images/devices/homepage-hvac-image-vaillant.webp',
					WOLTAIR_PL: '/images/devices/homepage-hvac-image-wltr.webp',
					WOLTAIR_DE: '/images/devices/homepage-hvac-image-vaillant.webp',
					WOLTAIR_IT: '/images/devices/homepage-hvac-image-geniaair.webp'
				}[PUBLIC_WOLTAIR_WEB_CODE] || '/images/devices/homepage-hvac-image-vaillant.webp',
			imageAlt: t('web.homepage.box.first.imageAlt')
		}}
	/>

	{#if PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_DE' || PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_IT'}
		<AdvantageBox
			textWidth="lg:basis-[65%]"
			subtextWidth="max-w-[100%]"
			imageCls="scale-125"
			content={{
				header: 'web.homepage.box.second.heading',
				text: 'web.homepage.box.second.text',
				imageUrl: '/images/devices/solar.webp',
				imageAlt: 'web.homepage.box.second.imageAlt'
			}}
		/>
	{:else}
		<AdvantageBox
			textWidth="lg:basis-[65%]"
			subtextWidth="max-w-[100%]"
			imageCls="scale-125"
			content={{
				header: 'web.homepage.box.second.heading',
				text: 'web.homepage.box.second.text',
				buttonText: 'web.homepage.box.second.buttonText',
				buttonUrl: `/${PHOTOVOLTAICS}`,
				buttonTestId: 'page-link-pv',
				headingAnchor: `/${PHOTOVOLTAICS}`,
				imageAnchor: `/${PHOTOVOLTAICS}`,
				imageUrl: '/images/devices/solar.webp',
				imageAlt: t('web.homepage.box.second.imageAlt')
			}}
		/>
	{/if}

	{#if data.featureConfig.service}
		<AdvantageBox
			textWidth="lg:basis-[70%]"
			subtextWidth="max-w-[60%]"
			content={{
				header: 'web.homepage.box.third.heading',
				text: 'web.homepage.box.third.text',
				buttonText: 'web.homepage.box.third.buttonText',
				buttonUrl: `/${SERVICE}`,
				buttonTestId: 'page-link-service',
				headingAnchor: `/${SERVICE}`,
				imageAnchor: `/${SERVICE}`,
				imageUrl: '/images/illustrations/technician.webp',
				imageAlt: 'Technik'
			}}
		/>
	{/if}
	{#if data.featureConfig.financing.hvac || data.featureConfig.financing.pv}
		<AdvantageBox
			textWidth="lg:basis-[65%]"
			subtextWidth="max-w-[80%]"
			imageCls="lg:scale-125 lg:-translate-x-6 lg:-translate-y-10"
			content={{
				header: 'web.homepage.box.fourth.heading',
				text: 'web.homepage.box.fourth.text',
				buttonText: 'web.homepage.box.fourth.buttonText',
				buttonUrl: `/${FINANCING}`,
				buttonTestId: 'page-link-financing',
				headingAnchor: `/${FINANCING}`,
				imageAnchor: `/${FINANCING}`,
				imageUrl: {
					WOLTAIR_CZ: '/images/illustrations/safe.webp',
					WOLTAIR_PL: '/images/illustrations/safe-nologo.webp',
					WOLTAIR_DE: '',
					WOLTAIR_IT: ''
				}[PUBLIC_WOLTAIR_WEB_CODE],
				imageAlt: t('web.homepage.box.fourth.imageAlt')
			}}
		/>
	{/if}
</section>

{#if data.featureConfig.thirtyFifty.hvac && PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_CZ' && PUBLIC_WOLTAIR_WEB_CODE !== 'WOLTAIR_PL'}
	<section class="page-wrapper">
		<div
			class="mb-8 mt-10 grid grid-cols-1 items-center gap-8 rounded-2xl bg-gradient-to-r from-primary-500 to-[#e0154a] px-6 py-10 text-white shadow-2xl lg:grid-cols-12"
		>
			<div class="flex justify-center text-center text-6.5xl font-extrabold lg:col-span-3">
				{#if PUBLIC_WOLTAIR_WEB_CODE === 'WOLTAIR_DE'}
					<img src="/images/logos/30-50-logo-DE.webp" class="max-h-44" alt="30/50" />
				{:else}
					30/50
				{/if}
			</div>
			<div class="max-sm:center text-lg font-extrabold md:text-left md:text-2.5xl lg:col-span-6">
				{t('web.homepage.PUBLIC.block3050.mainText')}
			</div>
			<div class="center text-center lg:col-span-3">
				<a
					href="/{HEAT_PUMP_3050}"
					class="whitespace-nowrap rounded-full bg-neutral-800 px-8 py-4 text-base text-white"
					>{t('web.homepage.PUBLIC.block3050.button.text')}</a
				>
			</div>
		</div>
	</section>
{/if}

{#if data?.referencesBlock && Object.values(data.referencesBlock)?.flat(1).length > Object.values(data.referencesBlock).length}
	<section class="page-wrapper my-20-res">
		<Separator />
		<h2 class="text-center !font-extrabold tracking-normal">
			{t('web.references.REFERENCES_BLOCK.heading')}
		</h2>

		<WebTabs.Tabs justify="center">
			{#each Object.entries(data.referencesBlock) as [deviceType, orders]}
				{#if orders.length > 0}
					<WebTabs.TabItem title={t(`INSTALLATION_CATEGORY.${deviceType}`)}>
						<div
							class="inset-x-0 mx-auto grid grid-cols-1 gap-x-6 pt-4 md:mx-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
						>
							{#each orders as order}
								<div class="flex items-center border-l-2 py-2 pl-4 text-left text-lg">
									<a
										href="/{INSTALLATION_CATEGORY_URL.cs[deviceType]}/{order.slug}"
										class="text-primary-500">{order.region} ({order.value})</a
									>
								</div>
							{/each}
						</div>
					</WebTabs.TabItem>
				{/if}
			{/each}
		</WebTabs.Tabs>
	</section>
{/if}

{#if data.featureConfig.blog && data.articles?.length > 0}
	<section class="page-wrapper">
		<BlogBanner articles={data.articles} />
	</section>
{/if}

{#if data.featureConfig.meeting.hvac || data.featureConfig.meeting.pv || data.featureConfig.expertAdvice}
	<section class="page-wrapper">
		<div class="column-m page-wrapper container mb-5 flex flex-col lg:flex-row">
			{#if data.featureConfig.meeting.hvac || data.featureConfig.meeting.pv}
				<ContactsBanner />
			{/if}
			{#if data.featureConfig.expertAdvice && data?.topics}
				<div class="queries-cont w-full xl:w-1/2">
					<Separator align="left" />
					<h2 class="mb-6 !font-extrabold tracking-normal">
						{t('web.homepage.PUBLIC.topics.heading')}
					</h2>
					<Topics topics={data.topics} showIsAnswered={false} marginTop="0px" marginBottom="0px" />
					<Button href="/{EXPERT_ADVICE}" cls="mt-5"
						>{t('web.homepage.PUBLIC.topics.button.text')}</Button
					>
				</div>
			{/if}
		</div>
	</section>
{/if}

<style>
	/*.header-advantages p {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header-advantages p::before {
		display: block;
		content: '';
		height: 2em;
		aspect-ratio: 1/1;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 1em;
	}*/
</style>
